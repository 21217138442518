
.bottom-copyright {
    width: 600px;
    height: 50px;
    margin: 0 auto;
    margin-left: -100px;
}
.login-main {
    background:#efefef;
    height: 100vh;
}
.login-box {
    width: 600px;
    margin: 0 auto;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 48px;
    background:#fff;
    border: 1px solid #efefef;
    border-radius: 8
}
.phone-login-box {
    width: 100vw;
    margin: 0 auto;
    background:#fff;
}
.login-icon {
    margin: 0 auto;
    width: 360px;
    height: 200px;
    background-image: url(/oo.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.login-body {
    margin: 0 auto;
    width: 360px;
    height: calc(100vh - 300px);
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 48px;
}
.phone-login-body {
    margin: 0 auto;
    width: 360px;
    height: calc(100vh - 200px);
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 48px;
}
.gongan-image {
    vertical-align: top;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    display: inline-block;
}