
.flow-main {
    height: 100%;
}

/*顶部工具栏*/
.flow-tooltar {
    box-sizing: border-box;
    height: 50px;
    border-bottom: 1px solid #DADCE0;
}

.flow-body {
    display: flex; 
    height: calc(100vh - 140px)
}

.flow-menu-main {
    width: 240px; 
    height: inherit;
    border-right: 1px solid #dce3e8
}

.flow-menu {
    width: inherit; 
    height: inherit;
    overflow-y: auto;
    border-right: 1px solid #dce3e8
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.flow-form {
    width: 300px;
    border-left: 1px solid #dce3e8;
    background-color: #fbfbfb;
}

.flow-board {
    position: absolute; 
    top: 2000px; 
    left: 2000px
}

/*画布容器*/
#efContainer {
    height: inherit;
    position: relative;
    overflow: scroll;
    flex: 1;
}

.jtk-overlay {
    cursor: pointer;
    color: #4A4A4A;
}


/*节点菜单*/
.ef-node-pmenu {
    cursor: pointer;
    height: 32px;
    line-height: 32px;
    width: 160px;
    display: block;
    font-weight: bold;
    color: #4A4A4A;
    padding-left: 5px;
}

.ef-node-pmenu:hover {
    background-color: #E0E0E0;
}

.ef-node-menu-li {
    color: #565758;
    width: 140px;
    border: 1px dashed #E0E3E7;
    margin: 5px 0 5px 0;
    padding: 5px;
    border-radius: 5px;
    padding-left: 8px;
    /* 设置超出宽度文本显示方式*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ef-node-menu-li:hover {
    /* 设置移动样式*/
    cursor: move;
    background-color: #F0F7FF;
    border: 1px dashed #1879FF;
    border-left: 4px solid #1879FF;
    padding-left: 5px;
}

.ef-node-menu-ul {
    list-style: none;
    padding-left: 20px
}

/*节点的最外层容器*/
.ef-node-container {
    position: absolute;
    display: flex;
    width: 160px;
    height: 32px;
    /*border: 1px solid #E0E3E7;*/
    border-radius: 5px;
    background-color: #fff;
    padding-top: 5px;
}

.ef-node-container:hover {
    /* 设置移动样式*/
    cursor: move;
    background-color: #F0F7FF;
    /*box-shadow: #1879FF 0px 0px 12px 0px;*/
    background-color: #F0F7FF;
    /*border: 1px dashed #1879FF;*/
}

/*节点激活样式*/
.ef-node-active {
    background-color: #F0F7FF;
    /*box-shadow: #1879FF 0px 0px 12px 0px;*/
    background-color: #F0F7FF;
    border: 1px solid #1879FF;
}

/*节点左侧的竖线*/
.ef-node-left {
    width: 4px;
    background-color: #1879FF;
    border-radius: 4px 0 0 4px;
}

/*节点左侧的图标*/
.ef-node-left-ico {
    padding-top: 1px;
    padding-left: 8px;
}

.ef-node-left-ico:hover {
    /* 设置拖拽的样式 */
    cursor: crosshair;
}

/*节点显示的文字*/
.ef-node-text {
    color: #565758;
    /*line-height: 32px;*/
    margin-left: 8px;
    width: 160px;
    /* 设置超出宽度文本显示方式*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
/*节点右侧的图标*/
.ef-node-right-ico {
    line-height: 32px;
    position: absolute;
    right: 5px;
    color: #84CF65;
    cursor: default;
}

/*节点的几种状态样式*/
.el-node-state-success {
    line-height: 32px;
    position: absolute;
    right: 5px;
    color: #84CF65;
    cursor: default;
}

.el-node-state-error {
    line-height: 32px;
    position: absolute;
    right: 5px;
    color: #F56C6C;
    cursor: default;
}

.el-node-state-warning {
    line-height: 32px;
    position: absolute;
    right: 5px;
    color: #E6A23C;
    cursor: default;
}

.el-node-state-running {
    line-height: 32px;
    position: absolute;
    right: 5px;
    color: #84CF65;
    cursor: default;
}


/*node-form*/
.ef-node-form-header {
    height: 32px;
    border-top: 1px solid #dce3e8;
    border-bottom: 1px solid #dce3e8;
    background: #F1F3F4;
    color: #000;
    line-height: 32px;
    padding-left: 12px;
    font-size: 14px;
}

.ef-node-form-body {
    margin-top: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
}

/* 连线中的label 样式*/
.jtk-overlay.flowLabel:not(.aLabel) {
    padding: 4px 10px;
    background-color: white;
    color: #565758 !important;
    border: 1px solid #E0E3E7;
    border-radius: 5px;
}

.ef-dot {
    background-color: #1879FF;
    border-radius: 10px;
}

.ef-dot-hover {
    background-color: red;
}

.ef-rectangle {
    background-color: #1879FF;
}

.ef-rectangle-hover {
    background-color: red;
}

.ef-drop-hover{
    border: 1px dashed #1879FF;
}

.handle{
    position: absolute;
    z-index:10;
    top: 0;
    bottom: 0;
    cursor: col-resize;
    padding:0 10px;
    margin-left: -10px;
}

.mapping-body-left {
    overflow-y: auto;
    border-right: 1px solid #DADCE0;
    height: calc(100vh - 120px)
}
.mapping-body-right {
    overflow-y: auto;
    height: calc(100vh - 120px)
}

.mapping-left-node-value {
    float: left;
    width: calc(100vw / 3 - 174px)
}
.mapping-left-node-bind {
    float: left;
    color: blue;
    width: calc(100vw / 3 - 174px)
}
.mapping-left-operation {
    width: 72px;
    float: right;
}
