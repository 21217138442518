
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.layout-sider {
  width: 220px;
  position: absolute;
  background: #001529;
  height: 100%;
  min-height: 100%;
  border-top: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}
.layout-main {
  overflow: hidden;
  min-width: 300px;
}

.layout-container-hashead {
  padding-bottom: 8px;
  height: calc(100vh - 40px);
  overflow: auto;
}
.layout-container {
  padding-bottom: 8px;
  height: 100vh;
  overflow: auto;
}
.layout-content {
  background: #fff;
  border-radius: 8px;
  padding-top:16px;
  padding-left:4px;
  padding-right:4px;
  margin: 0px 8px;
}

.layout-header {
  background: #FAFAFA;
  height: 40px;
  padding-left: 8px;
  font-size: 13px; 
  font-weight: bold;
  line-height: 40px;
}
.autobr {
  width:100%;
  word-break:normal;
  display:block;
  word-wrap:break-word;
  overflow:hidden;
}

/*集成 react-resizable 来实现可伸缩列-----------------------*/
.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}
/*集成 react-resizable 来实现可伸缩列------------------------*/

/*上传组件样式-----------------------------------------------*/
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
/*上传组件样式-----------------------------------------------*/

/* 相册列表样式*/
.infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 0px 0px;
  height: calc(100% - 80px);
}
.loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

/* 相册内照片列表样式*/
.item-infinite-container {
  /*border: 1px solid #e8e8e8;*/
  border-radius: 4px;
  overflow: auto;
  padding: 0px 0px;
  height: calc(100% - 240px);
}

.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 0px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.anchor {
  margin-left:8px;
  color: #fff;
}

a:hover {
  color:#40a9ff
}

a:active {
  color:#096dd9
}

.ant-back-top {
  top: 40px;
  right: 0px;
}
.anchor-folder {
  width: 30px;
  position: fixed;
  top: 56px;
  right: 40px;
  z-index: 66;
}
.anchor-div {
  position: fixed;
  top: 56px;
  right: 80px;
  z-index: 66;
}

.centered-ch {
  display: inline-block;
  /* display: flex; */
  /* align-items: center; */
}

.centered-sp {
  display: flex;
  justify-content: center;  /* 水平居中 */
}

.flex-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;      /* 垂直居中 */
  justify-content: center;  /* 水平居中 */
}

.chart-grid1 {
  width: 100vh;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr; /* 创建两列 */
  gap: 16px; /* 可选，设置网格间隔 */
}
.chart-grid2 {
  width: 100vh;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr; /* 创建两列 */
  gap: 16px; /* 可选，设置网格间隔 */
}
.chart-grid3 {
  width: 100vh;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* 创建两列 */
  gap: 16px; /* 可选，设置网格间隔 */
}
.chart-grid4 {
  width: 100vh;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; /* 创建两列 */
  gap: 16px; /* 可选，设置网格间隔 */
}
.chart-grid5 {
  width: 100vh;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; /* 创建两列 */
  gap: 16px; /* 可选，设置网格间隔 */
}
.chart-grid6 {
  width: 100vh;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; /* 创建两列 */
  gap: 16px; /* 可选，设置网格间隔 */
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: flex-start;  
}

.space-between {
  display: flex;
  flex-direction: row;
  align-items: center;      /* 垂直居中 */
  justify-content: space-between;
}

.between {
  display: flex;
  justify-content: space-between;
}

.between span {
  /* 可选：添加固定宽度或允许内容撑开 */
  display: inline-block; /* 或无需设置，因 Flex 子项默认行为 */
}


.icon-button {
  width: 32px;
  padding: 0;
}

.menu-icon {
  font-size: 16px;
  margin-right: 8px;
  width: 20px;
}

.ellipsis {
  /* 设置超出宽度文本显示方式*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-upload-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
 
.file-upload {
  position: absolute;
  font-size: 100px;
  opacity: 0;
  right: 0;
  top: 0;
}
 
.file-upload-button {
  display: inline-block;
  cursor: pointer;
}
 
.file-upload-button:hover {
  background-color: #e9e9e9;
}

/*
  max-width: 575px
  phone vertical hide expand icon 
  .ant-table-expand-icon-col,
  .ant-table-expand-icon-th,
  .ant-table-row-expand-icon-cell {
    width: 0px;
    min-width: 0px;
    overflow: hidden;
  }
*/

.circle {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 24px;       /* 宽度 */
  height: 24px;      /* 高度 */
  border: 2px solid;  /* 边框宽度 */
  border-radius: 50%; /* 使边框变圆 */
  border-color: #1890ff;
}
.circle span {
  color: #1890ff;
  font-weight: 200;
  transform: scale(0.9); /* 缩放 */
}

/**
TableEx
*/

/*表格行背景颜色间隔显示*/
.tableRow-0 {
  background: #8ec8fe08;
}
.ootable .tableRow-0, 
.ootable .tableRow-0 {
  height: 10px;
}
/*表格编辑模式下去掉组件下方空格*/
td .ant-form-item {
  margin-bottom: 0;
}

/*模仿Excel风格
.ant-table-tbody 
// 默认
overflow-wrap: break-word;
// 超出隐藏
overflow: hidden;
white-space: nowrap;

.ant-table-thead > tr > th, 
.ant-table-tbody > tr > td {
  overflow: hidden;
  white-space: nowrap;
}

*/

.ootable .ant-table-tbody > tr > td {
  padding: 0px 4px;
}
/*
.ootable .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ootable .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ootable .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ootable .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ootable .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ootable .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ootable .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ootable .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
*/
.ootable .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ootable .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ootable .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ootable .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ootable .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ootable .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ootable .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ootable .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 0px 4px;
}
/*
.ootable .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ootable .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ootable .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ootable .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ootable .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ootable .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ootable .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ootable .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
*/
.ootable .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ootable .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ootable .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ootable .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ootable .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ootable .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ootable .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ootable .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 0px 4px;
}